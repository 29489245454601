import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import DangerousMarkdownContent from "../DangerousMarkdownContent/DangerousMarkdownContent";
import { markdownUnwrapper } from "../../../utils/markdown-unwrapper";

const ContactInfo = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { de: { template: { eq: "contact-page" } } } }
      ) {
        edges {
          node {
            frontmatter {
              de {
                contact
                address
              }
              en {
                contact
                address
              }
            }
          }
        }
      }
    }
  `);
  const { language, t } = useI18next();
  const contact = markdownUnwrapper(data, language)[0];

  return (
    <div className="contact-info-area pt-100 pb-70">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-6">
            <div className="contact-info-box">
              <div className="back-icon">
                <i className="bx bx-map"></i>
              </div>
              <div className="icon">
                <i className="bx bx-map"></i>
              </div>
              <h3>{t("contact:our-address")}</h3>
              <div>
                <p>
                  <DangerousMarkdownContent content={contact.address} />
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="contact-info-box">
              <div className="back-icon">
                <i className="bx bxs-contact"></i>
              </div>
              <div className="icon">
                <i className="bx bxs-contact"></i>
              </div>
              <h3>{t("contact:contact")}</h3>
              <div>
                <p>
                  <DangerousMarkdownContent content={contact.contact} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
