import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import SEO from "../timewise-custom/components/App/SEO";
import Layout from "../timewise-custom/components/App/Layout";
import Navbar from "../timewise-custom/components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import ContactInfo from "../timewise-custom/components/Contact/ContactInfo";
import ContactForm from "../timewise-custom/components/Contact/ContactForm";
const Contact = () => {
  const { t } = useI18next();

  return (
    <Layout>
      <SEO title={t("navigation:contact")} />
      <Navbar />
      <PageBanner
        pageTitle={t("navigation:contact")}
        homePageText={t("navigation:home")}
        homePageUrl="/"
        activePageText={t("navigation:contact")}
      />
      <ContactInfo />
      <ContactForm />
    </Layout>
  );
};

export default Contact;
