import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/index";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import DangerousMarkdownContent from "../DangerousMarkdownContent/DangerousMarkdownContent";
import owlIcon from "../../../assets/images/owl.png";
import { markdownUnwrapper } from "../../../utils/markdown-unwrapper";

const ContactForm = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { de: { template: { eq: "contact-page" } } } }
      ) {
        edges {
          node {
            frontmatter {
              de {
                title
                description
                image {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 75) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              en {
                title
                description
              }
            }
          }
        }
      }
    }
  `);
  const { language, t } = useI18next();
  const { title, description, image } = markdownUnwrapper(data, language)[0];

  return (
    <section className="contact-area pb-100">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={owlIcon} alt={t("contact:get-in-touch")} width={30} />{" "}
            {t("contact:get-in-touch")}
          </span>
          <h2>{title}</h2>
          <p>
            <DangerousMarkdownContent content={description} />
          </p>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="contact-image">
              <Img
                alt={t("contact:title")}
                style={{
                  height: "100%",
                  width: "100%",
                  maxWidth: 800,
                }}
                imgStyle={{
                  objectFit: "contain",
                }}
                fluid={image.childImageSharp.fluid}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="contact-form">
              <form
                action="/contact-sent"
                name="contact-form"
                id="contactForm"
                data-netlify="true"
                method="post"
                data-netlify-honeypot="falle"
              >
                <input type="hidden" name="form-name" value="contact-form" />
                <p className="d-none">
                  <input name="falle" type="text" />
                </p>
                <div className="row">
                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        required
                        placeholder={t("contact:your-name")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        required
                        placeholder={t("contact:your-email-address")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone_number"
                        className="form-control"
                        placeholder={`${t("contact:your-phone-number")} (${t(
                          "common:optional"
                        )})`}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        className="form-control"
                        cols="30"
                        rows="6"
                        required
                        placeholder={t("contact:write-your-message")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <button type="submit" className="default-btn">
                      <i className="flaticon-tick"></i>
                      {t("contact:send-message")} <span></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
